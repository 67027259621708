

import axios from 'axios';
import Swal from 'sweetalert2';

// Create an instance of axios
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_EHR, // Base URL for your API
  timeout: 10000, // Timeout in milliseconds
});


// Request Interceptor
apiClient.interceptors.request.use(
  (config) => {
    // Modify the request config here (e.g., add Authorization header)
      const token = sessionStorage.getItem('token'); // Get the token from localStorage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request error here
    Swal.fire({
      icon: 'error',
      title: 'Request Error',
      text: 'An error occurred while sending the request. Please try again.',
    });
    return Promise.reject(error);
  }
);

// Response Interceptor
apiClient.interceptors.response.use(
  (response) => {
    // You can modify the response data here if needed
    return response;
  },
  (error) => {
    // Handle errors globally here (e.g., show error notification)
    if (error.response) {
      const { status, data } = error.response;

      // Customize the error handling based on the status code
      if (status === 401) {
        // Unauthorized error
        Swal.fire({
          icon: 'warning',
          title: 'Unauthorized',
          text: 'You are not authorized. Please log in again.',
        });
      } else if (status === 404) {
        // Not Found
        Swal.fire({
          icon: 'error',
          title: 'Not Found',
          text: 'The requested resource was not found.',
        });
      } else if (status === 500) {
        // Server Error
        Swal.fire({
          icon: 'error',
          title: 'Server Error',
          text: 'An internal server error occurred. Please try again later.',
        });
      } else {
        // Generic error for other status codes
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: data.message || 'An unexpected error occurred. Please try again.',
        });
      }
    } else {
      // Network or other errors
      Swal.fire({
        icon: 'error',
        title: 'Network Error',
        text: 'A network error occurred. Please check your connection.',
      });
    }
    
    // Reject the error so that it's handled by the calling code if needed
    return Promise.reject(error);
  }
);

export default apiClient;
